/* Global constants */

export const appName = 'admin';

export const restaurantSourceType = {
  restaurant: 'RS',
};

export const regex = {
  alphaNumericNoSpaceValidator: '^[a-zA-Z0-9]*$',
  priceValidator: '^([0-9]{0,5})',
  emailValidator: /^[a-zA-Z0-9](?!.*?\.\.)[a-zA-Z0-9-_\.]+@([a-zA-Z0-9_\-.]+){2}\.([a-zA-Z]{2,5})$/,
  phoneNumberValidator: /^[(]{0,1}[0-9]{1,4}[)]{0,1}[\s/0-9]*$/,
  phoneNumberValidatorAlt: /^[0-9]{10}$/,
  linkValidator:
    '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[\\:;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
  offerCodeValidator: /^(?!(0))[a-zA-Z0-9]{3,25}/,
  numberValidator: '^[0-9]*$',
  noEmojiValidator: '^[a-zA-Z0-9\\s\\ -/:-@[-`{-~]*$',
  nameValidator: `^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$`,
  domainNameValidator: `^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$`,
  userNameValidator: `^[a-z0-9_-]*$`,
};

export const submitFallbackText = 'SUBMIT';

export const menuItemDetailsPopupFallbackTitle = 'Menu Item Details';

export const reorderDirection = {
  up: 'Move Item Up',
  down: 'Move Item Down',
};

export const itemOps = {
  add: 'ADD',
  edit: 'EDIT',
  delete: 'DELETE',
};

export const mediaOps = {
  addMedia: 'ADD_MEDIA',
  editMedia: 'EDIT_MEDIA',
};

export const customizationOps = {
  addCustomization: 'ADD_CUSTOMIZATION',
  editCustomization: 'EDIT_CUSTOMIZATION',
};

export const menuCategoryOps = {
  addCategory: 'ADD_CATEGORY',
  editCategory: 'EDIT_CATEGORY',
};

export const menuCategoryTypes = {
  main: 'MAIN_CATEGORY',
  sub: 'SUB_CATEGORY',
};

export const recommendedMenuItemsLimit = 30;

export const recommendedCategory = {
  name: 'Recommended',
};

export const componentLayers = {
  container: 'container',
  popup: 'popup',
};

export const apiRequestStatusCodes = {
  SUCCESS: [200, 201, 202, 203, 204, 205, 206],
  FORBIDDEN: [403],
  NOT_FOUND: 400,
};

export const mediaDefaultAspectRatio = 16 / 10;

export const cropPanelWidthInPercent = 100;

export const cropPanelWidthUnit = '%';

export const defaultNewFileName = 'newFile.jpeg';

export const canvasEmptyErrorLogMessage = 'Canvas is empty';

export const mediaCategoryCodes = {
  image: 'IM',
};

export const mediaTypeCodes = {
  image: 'IMG',
};

export const defaultMinCropWidth = 328; // In pixels

export const serverResourceOps = {
  create: 'C',
  delete: 'D',
};

export const restaurantTablesConfig = {
  pageSize: 100,
  defaultSort: 'id,DESC',
};

export const dineInRoomOrder = ['DI', 'RO'];

export const otherOrder = ['DL', 'SP'];

export const carOrder = ['DT'];

export const acceptableMenuItemImageTypes = ['image/jpeg', 'image/png'];

export const maxImageFileSizeInMB = 5;

export const phoneNumberMinLength = 10;

export const phoneNumberMaxLength = 12;

export const paymentTypes = {
  PREPAYMENT: 'PRE_PAYMENT',
  POSTPAYMENT: 'POST_PAYMENT',
  loyaltyPaymentTypes: { CASHCARD: 'cc' },
};

export const orderStatusCode = {
  FAILED: 'FAILED',
  SETTLED: 'SETTLED',
  CONFIRMED: 'CONFIRMED',
  READY: 'READY',
  UNATTENDED: 'UNATTENDED',
  CANCELLED: 'CANCELLED',
};

export const serviceTypes = [
  { code: 'DI', name: 'Dine in Orders' },
  { code: 'SP', name: 'Self Pick-up Orders' },
  { code: 'DL', name: 'Home Delivery Orders' },
  { code: 'DT', name: 'Drive Through Orders' },
];

export const offerTypeCode = {
  DirectDiscount: 'DD',
  ItemOffer: 'IO',
  QuantityDiscount: 'QD',
  ItemFree: 'IF',
  ComboOffer: 'CO',
};

export const offerMode = {
  viewOffer: 'view-offer',
  createOffer: 'create-offer',
  editOffer: 'edit-offer',
  pauseOffer: 'pause-offer',
  resumeOffer: 'resume-offer',
  archiveOffer: 'archive-offer',
  deleteOffer: 'delete-offer',
  cloneOffer: 'clone-offer',
  READY: 'READY',
  UNATTENDED: 'UNATTENDED',
};

export const menuCategoryRuleTypes = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export const menuServiceTypeStatus = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export const menuEntityUnavailabilityTagColor = 'purple';

export const fallbackTime = '00:00';

export const atLeastOneAlphaNumericCharValidator = '[a-zA-Z0-9]+';

export const restaurantActivityStatus = {
  active: 'ACTIVE',
  inactive: 'DEACTIVE',
  read: 'READ',
};

export const settingsPanelIds = {
  orderModes: 'orderModes',
  deliveryLocations: 'deliveryLocations',
  qrCodes: 'qrCodes',
  pricing: 'pricing',
  timings: 'timings',
  notifications: 'notifications',
  widgets: 'widgets',
  users: 'users',
  documents: 'documents',
};

export const accountPanelIds = {
  accountInfo: 'accountInfo',
  subscriptionPlanDetails: 'subscriptionPlanDetails',
};

export const communicationSettingsModals = {
  guestCommunication: 'guestCommunication',
  notificationEmail: 'notificationEmail',
};

export const additionalChargeTypes = {
  deliveryCharges: 'DC',
};

export const chargeUnits = [
  { code: 'PER', title: 'Percent', symbol: '%' },
  { code: 'ABS', title: 'Rupees', symbol: '₹' },
];

export const deliveryChargeRuleTypes = {
  fixed: 'FX',
  orderValue: 'OV',
  free: 'FR',
  deliveryDistance: 'OD',
};

export const serviceModes = {
  selfPickup: 'SP',
  delivery: 'DL',
  room: 'RO',
  driveThrough: 'DT',
  dineIn: 'DI',
};

export const restaurantConfigModes = {
  ordersMode: 'mode',
  assistanceMode: 'assistanceMode',
};

export const settingsConfirmationModals = {
  orderStatus: 'orderStatus',
  additionalServicesStatus: 'additionalServicesStatus',
  customLinks: 'customLinks',
  addQrCode: 'addQrCode',
};
export const printDelay = 1000; // Delay before print execution in milliseconds

export const pricingMode = {
  inclusiveTax: 'IN-TAX',
  exclusiveTax: 'EX-TAX',
};

export const qrCodeDefaultPageSize = '300';

export const browserList = {
  safari: 'safari',
};

export const amountPrecisionValue = 2;

export const offersPaginationConfig = {
  pageSize: 20,
  sortType: 'code,ASC',
};

export const notificationSettingsModals = {
  orderNotification: 'orderNotification',
};

export const browserMediaOps = {
  play: 'PLAY',
  pause: 'PAUSE',
  stop: 'STOP',
};

export const offerTypeOptions = [
  {
    key: 'DD',
    text: 'Discount on Bill',
    description: '20% off on bill of ₹500, ₹25 off on bill of ₹500',
    value: 'DD',
  },
  // { key: 'QD', text: 'Discount on next items', description: '20% off on bill on 2nd item, ₹200 off on bill on 2nd item', value: 'QD' }, commenting for now
  {
    key: 'IO',
    text: 'BOGO',
    description: 'Buy 1 Get 1 free, Buy 2 Get 2 Free',
    value: 'IO',
  },
];

export const pointUnits = {
  percent: 'PER',
  absolute: 'ABS',
  item: 'ITM',
};

export const UIElements = {
  popupSubTitle: 'popupSubTitle',
  toast: 'toast',
};

export const emailTypes = {
  newOrder: 'NEW_ORDER',
  orderSummary: 'ORDER_SUMMARY',
  offer: 'OFFER',
  guestList: 'GUEST_LIST',
  cancelOrder: 'CANCEL_ORDER',
  customerSupport: 'CUSTOMER_SUPPORT',
};

export const materialToolTip = {
  placement: {
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
  },
};

// List of API endpoints and other third-party endpoints excluded from internet disconnectivity error handling.
export const internetDisconnectivityHandledAPIEndpointBlacklist = ['/orders/checkcurrent'];

export const highAvailabilityServerAddress = 'https://www.google.com';

export const internetErrorResponseStoreDispatchDelay = 500; // in ms

export const itemType = {
  menuItem: 'MI',
  menuCategory: 'MC',
  selectedCategory: 'SC',
  selectedItem: 'SI',
  anyItem: 'AI',
};

export const accountInfoSettingsModals = {
  changeLogo: 'changeLogo',
  editInfo: 'editInfo',
};

export const acceptableLogoImageTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const recaptchaSiteKey = '6LcnI9oZAAAAAKCIM4vtimmEV8EKjSMaPxoMwQEv';

export const notificationPlaybackModes = {
  single: 'S',
  continuous: 'C',
};

export const logoAspectRatio = 9 / 5;

export const chargeDecimalPrecision = 2;

export const socialMediaCode = {
  facebookCode: 'social.facebook.profile',
  instagramCode: 'social.instagram.profile',
};

export const httpProtocol = 'http';

export const analyticsVendorType = { GTM: 'GTM' };

export const acceptableLogoImageMIMETypes = ['image/png', 'image/jpeg', 'image/jpg'];

export const acceptableLogoImageFormats = ['jpg', 'png', 'jpeg'];

export const composeWithWhiteBackgroundCommand = [
  'convert',
  'inputImage.png',
  '-resize',
  '512x512',
  '-background',
  'white',
  '-compose',
  'Copy',
  '-gravity',
  'center',
  '-extent',
  '512x512',
  'androidIcon.png',
];

export const aspectRatio = 1;

export const transactionSubStatusConfig = {
  userDropped: 'User_Dropped',
  invalid: 'Invalid',
};

export const paymentTypesTitles = {
  PRE_PAYMENT: 'Pre Paid',
  POST_PAYMENT: 'Post Paid',
};
